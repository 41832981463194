@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.accountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  color: black;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 60px);
  font-family: "Poppins", sans-serif;
}

.main_layout {
}

.tab_layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 20px; /* Add spacing between sections */
}

.settingsTitle {
  margin: 0;
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: lighter;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  min-height: 60px;
}
/* background: rgba(255, 255, 255, 0.15); */

.tab {
  padding: 10px 15px;
  font-size: 1.2em;
  font-weight: 300;
  color: black;
  transition: color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
}

.tab:not(:last-child) {
  margin-right: 15px;
}

.tab:hover {
  color: #cd1c18;
}

.active {
  color: #cd1c18;
}

.underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  background: #cd1c18; 
  transition: left 0.3s ease, width 0.3s ease;
}

.section {
  width: 100%;
  margin-bottom: 20px;
}

.profile_picture_bg {
  width: 75px;
  height: 75px;
  border-radius: 40%;
}

.profile_picture {
  width: 100%;
  height: 100%;
  border-radius: 40%;
  object-fit: cover;
}

.section_title {
  text-align: start;
  font-size: 22px;
  font-weight: 300;
}

.profile_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.proflie_picture_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.update_profile_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 45px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: #cd1c18;
  margin-left: 50px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.update_profile_picture:hover {
  background-color: rgba(240, 55, 55, 0.8);
}

.profile_picture_section_left {
  width: 49%;
}
.profile_picture_section_right {
  width: 49%;
}
.single_line_input {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.input_title {
  margin: 5px 0 10px 0; /* Add margin for spacing between label and input */
  text-align: left;
  font-size: 16px;
  color: #333;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
