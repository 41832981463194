/* TestClassificationComponent.module.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  background-color: #00000070;
  backdrop-filter: blur(4px);
  min-height: 100vh;
  background-blend-mode: overlay;
  background-image: url("https://i.ibb.co/B3yDkpV/pexels-larissa-megale-1076233.jpg");
  background-size: cover;
  background-position: center top;
}

.title {
  text-align: center;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 28px;
}

.uploadForm {
  max-width: 400px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.button {
  background-color: #cd1c18;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.resultContainer {
  max-width: 400px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
