.filter_icon {
  width: 24px;
  height: 24px;
}

.search_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.search_icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.search_box_input {
  color: white;
  width: 75%;
  height: 80%;
  border: none;
  margin-left: 10px;
  background-color: transparent;
  outline: none;
}

.search_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  border-radius: 2.5px;
  margin-bottom: 20px;
}

.search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 90%; */
  flex-grow: 1;
  height: 100%;
  /* background-color: rgba(50, 50, 50, 0.2); */
  margin-right: 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.search_icon {
  width: 30px;
  height: 30px;
  opacity: 0.25;
}

.search_divider {
  height: 50%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 5px;
  margin-right: 5px;
}
.search_input {
  height: 80%;
  color: rgba(0, 0, 0, 1);
  background-color: transparent;
  border: none;
  outline: none;
}

.search_input::placeholder {
  opacity: 0.5;
}

.search_filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.filter_menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%; /* Position it right below the filter icon */
  right: 0;
  margin-top: 20px;
  min-width: 300px; /* Minimum width for the dropdown, adjust as needed */
  background-color: #fff;
  border: 1px solid rgba(0, 61, 130, 1);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other elements */
  padding: 8px 0;
}

.filter_menu_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  height: 100%;
  width: 90%;
}

.search_filter:hover {
  background-color: rgba(0, 61, 130, 0.8);
}

.search_filter_icon {
  width: 70%;
  height: 70%;
}
