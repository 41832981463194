.home_components {
  width: 100vw;
  /* height: 100vh; */
}

.logo_icon {
  height: 55px;
}

.landing_image {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home_header_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.home_header_content_right {
  position: absolute;
  display: flex;
  justify-content: end;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* .page_title {
  color: #0058dc;
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 50px;
} */
.page_title {
  color: black;
  font-weight: 200;
  font-size: 32px;
  margin-bottom: 40px;
  opacity: 0.75;
}

.sidebar {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 45%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* Semi-transparent white background */
  border-right: 2px solid #383838;
  box-sizing: border-box;
  padding: 30px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.sidebar_right {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 45%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* Semi-transparent white background */
  border-left: 2px solid #000000;
  box-sizing: border-box;
  padding: 30px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.two_column_inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.half_input_object {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 48%;
}

.email_input {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
}

.dropdown_input_option {
  width: 100%;
}

.dropdown_input {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
}

.dropdown_input_element {
  border: none;
  outline: none;
  flex-grow: 1;
  margin-left: 15px;
}

/* Add this to your CSS file, or create a new CSS file if needed */
.dropdown_options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid #ccc;
  width: 50%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  background-color: #fff;
}

.dropdown_item {
  display: flex;
  justify-content: start;
  /* height: 55px; */
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.dropdown_item:hover {
  background-color: rgba(220, 0, 0, 0.2);
}

.input {
  background-color: transparent;
  margin-left: 10px;
  flex-grow: 1;
  border: none;
  outline: none;
  margin-right: 20px;
}

.input::placeholder {
  color: black;
  opacity: 0.2;
  font-weight: 400;
}

.page_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: rgba(220, 0, 0, 0.8);
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.page_button:hover {
  background-color: rgba(220, 0, 0, 0.5);
}

.page_button_inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  /* background: rgba(0, 0, 0, 0.2); */
  background-color: rgba(220, 0, 0, 0.2);
  color: #cd1c18;
  opacity: 0.5;
  margin-top: 20px;
  margin-bottom: 10px;
}

.join_button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.join_button_text {
  font-size: 15px;
  font-weight: 400;
}

.note {
  color: rgba(0, 0, 0, 0.4);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.clickable_text {
  color: #ff0000;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .sidebar {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    background-color: white;
    border-right: 2px solid #ff0000;
    box-sizing: border-box;
    padding: 30px;
  }
}

.logo {
  width: 100px;
  object-fit: cover;
}

.login_input_titles {
  /* height: 60px; */
  margin: 0;
  margin-bottom: 8px;
  opacity: 0.4;
}

.error_text {
  color: red;
  margin: 0;
}

.auth_input_icon {
  margin-left: 20px;
  width: 16px;
  height: 16px;
  object-fit: cover;
}

.forgot_password {
  color: #cd1c18;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
  font-weight:bold
}

.forgot_password:hover {
  text-decoration: underline;
}

.success_text {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.sign_up_remark {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: gray;
}

.sign_up_link {
  color: #cd1c18; /* Adjust this to match your theme */
  cursor: pointer;
  font-weight: bold;
}

.sign_up_link:hover {
  text-decoration: underline;
}