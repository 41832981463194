.root {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
}
.drawer {
  width: 250px;
  border: none !important;
}

.drawerPaper {
  width: 250px;
  background: linear-gradient(
    160deg,
    rgba(0, 0, 0, 0.7), 
    rgba(205,28,24, 0.9) 20%
  ); /* Linear gradient from red to black */
  backdrop-filter: blur(15px) saturate(180%); /* Glass effect */
  -webkit-backdrop-filter: blur(10px) saturate(180%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  border-radius: 25px;
  overflow-y: auto !important;
  min-height: 80vh;
  max-height: 95vh;
  margin: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}


.content {
  margin-top: 60px;
  margin-left: 270px; /* Slightly larger margin for sidebar spacing */
  background-color: white;
  height: calc(100vh - 60px);
}

.sidebar_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
}

.sidebar_icon_image {
  width: 30px;
  height: 30px;
  object-fit: contain; /* Ensures icon fits within container */
  filter: brightness(0) invert(0); /* Inverts dark icons to white */
  transition: filter 0.3s ease; /* Smooth transition for hover or active states */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.0); /* Subtle shadow around the icon */
}

.listItemText {
  font-size: 16px;
  font-family: "Poppins";
  color: white;
}

.sidebar_bottom_layout {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.sidebar_bottom_options {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* ######### */
.navbar_sidebar_extension {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  
}

.sidebar_logo {
  margin-left: 15px;
  height: 50px;
  object-fit: cover;
  margin-top: 20px
}
.sidebar_menu_container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the items vertically */
  padding-top: 20px; /* Add padding above the list to shift it down */
}

.sidebar_tab_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
  user-select: none;
  
}
.active_list_item_content {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.2); /* Darker background for active item */
  height: 100%;
  color: white; /* Bright white text for active item */
  user-select: none;
  border-radius: 0px;
}

.list_item_content {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
   /* Light background for non-active items */
  color: white; /* White text for non-active items */
  user-select: none;
  border-radius: 0px;
  padding: 0 10px;
}

.list_item_content:hover {
  background-color: rgba(255, 255, 255, 0.3); /* Slightly more visible on hover */
  color: white;
}

.active_sidebar_text {
  color: black; /* Bright text for active item */
  font-family: 'Poppins', sans-serif;
  margin: 0;
  margin-left: 15px;
}

.sidebar_text {
  color: black; /* White text for non-active items */
  font-family: 'Poppins', sans-serif;
  margin: 0;
  margin-left: 15px;
}

