.notifications_sidebar {
  display: flex;
  flex-direction: column;
  /* width: 250px; */
  /* height: calc(100vh - 80px); */
  width: 100%;
  height: 100%;

  /* z-index: 9999; */
}

.notifications_sidebar_text {
  color: rgb(0, 0, 0);
  font-size: 25px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  margin: 0;
}
.notifications_sidebar_content {
  margin-top: 10px;
  
}

.notification_cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.02);
  height: 50px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  position: relative; /* Required for absolute positioning of the dot */
}

.notification_cell_text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1; /* Ensures text takes up available space */
}

.notif_image {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 15px;
}

.notification_text {
  margin: 0;
  color: rgb(0, 0, 0);
  font-size: 12px;
}

.notification_time {
  margin-left: 0px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  opacity: 0.6;
}

.notification_image {
  margin: 0;
  color: white;
  font-size: 10px;
}

.green_word {
  margin: 0;
  color: #2ebb55;
  font-size: 10px;
}
.unread_dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  top: 0;
  right: 0; /* Aligns the dot to the top-right corner of the notification */
  transform: translate(3000%, -50%); /* Fine-tunes the alignment */
}
