/* Reset and Global Styling */
@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

/* Global Body Styling */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white; /* Set the global background to white */
  font-family: "Inter", sans-serif;
}


/* Consistent Styling with the Rest of Classifications Component */
.main_layout {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100vh; /* Make sure it takes full viewport height */
  box-sizing: border-box;
}

.page_title {
  align-self: start;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: black;
  margin: 0;
  margin-bottom: 20px;
}

.table_scroll {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;

}

.classifications_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 110px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 10px;
}

.classifications_card:hover {
  background-color: #c02c2c4f;
}

.classifications_image {
  width: 90px;
  height: 90px;
  border-radius: 5px;
  object-fit: cover;
}

.classifications_info {
  display: flex;
  flex-grow: 1;
  height: 100%;
  margin-left: 10px;
}

.classifications_subinfo {
  display: flex;
  align-items: start;
  flex-direction: column;
}
/* New styles for edited cards */
.edited_card {
  border-color: #af4c4c; /* Green border to indicate success */
  box-shadow: 0 0 10px rgba(175, 76, 76, 0.5); /* Green glow */
}

/* Optionally, you can animate the highlight */
@keyframes highlight {
  from {
    background-color: #e8f5e9; /* Light green background */
  }
  to {
    background-color: transparent;
  }
}


.edited_text {
  font-size: 12px;
  color: #ff0000;
}
.classification_title {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}

.classification_info_title {
  margin: 0;
  font-size: 15px;
  font-weight: 300;
  opacity: 0.8;
  margin-bottom: 3px;
}

.sort_dropdown {
  padding: 8px 16px; /* Adjusted padding for more space */
  padding-right: 20px; /* Extra padding on the right to move the arrow further */
  border-radius: 12px; /* More rounded edges */
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #ff0000; /* Red background color */
  color: white;
  font-size: 16px;
  cursor: pointer;
  box-shadow: var(--shadow);
  margin-bottom: 15px; /* Matches spacing similar to other elements */
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling in Safari */
  -moz-appearance: none; /* Remove default styling in Firefox */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='3 -1.75 15 20'%3E%3Cpath fill='white' d='M7 7l3 3 3-3z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center; /* Adjusted position of the arrow */
  min-width: 120px; /* Set a minimum width to accommodate longer text */
  text-align: left; /* Ensure text aligns properly */
  font-family: "Poppins", sans-serif;
}

.sort_text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-left: 5px;
}


.sort_dropdown:focus {
  outline: none;
  border-color: rgba(0, 0, 0, 0.7);
}

.sort_dropdown option {
  background-color: white;
  color: black;
}

.classifications_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.classifications_card {
  cursor: pointer;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it’s above everything else */
}

.modal_content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.1); /* Add border around the modal */
  max-width: 400px; /* Make the modal smaller */
  width: 90%; /* Adjust width for responsiveness */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow for pop effect */
  position: relative; /* To position the close button */
}

.close_button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Darker semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it’s above everything else */
}

.modal_image {
  width: 100%;
  max-height: 200px; /* Limit image height */
  object-fit: contain; /* Maintain aspect ratio */
  margin-bottom: 15px;
}

.input_field {
  width: 100%;  /* Ensure it takes up the available space */
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #000000;
  border-radius: 4px;
  font-size: 16px;
  display: block; /* Ensure it displays as a block element */
}

.change_class_button {
  background-color: #cd1c18;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Full-width button */
  margin-top: 10px; /* Add margin between button and input */
}
.cancel_button {
  background-color: #898989;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Full-width button */
  margin-top: 10px; /* Add margin between button and input */
}
.cancel_button:hover {
  background-color: #666666;
}

.change_class_button:hover {
  background-color: #b21815; /* Darker red on hover */
}
.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it’s on top of everything else */
}

.popup_content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.close_button {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  font-weight: bold;
}

.popup_image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 12px;
}
